import Subject from './subject';

interface StateTarget {
  [key: string]: any;
}

interface StateOptions {
  skipUnchanged?: boolean;
}

type State<S> = [S, Subject]

const createState = <S extends StateTarget>(target: S, { skipUnchanged = true }:StateOptions = {}): State<S> => {
  const subject = new Subject();
  const proxy = new Proxy(target, {
    set(proxyTarget: StateTarget, key: string, val) {
      const oldValue = proxyTarget[key];

      if (skipUnchanged && oldValue === val) return true;

      proxyTarget[key] = val;
      subject.fire(subject, {
        type: oldValue === undefined ? 'add' : 'change',
        object: target,
        name: key,
        oldValue,
      });

      return true;
    },
  });

  return [proxy, subject];
};

export default createState;
