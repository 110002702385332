import UiElement from '../../../shared/ui-element/ui-element';

import './store-sidebar.scss';

class StoreSidebar extends UiElement {
  ui = {
    sidebarOpen: '.store-sidebar__btn',
    sidebarMenu: '.store-sidebar__menu',
    sidebarClose: '.store-sidebar__close',
    sidebarBackground: '.store-sidebar__menu-background',
  };

  uiCollections = {
    sidebarLinks: '.store-sidebar__menu-link',
  };

  events = {
    'click @ui.sidebarOpen': 'openSidebar',
    'click @ui.sidebarBackground': 'closeSidebar',
    'click @ui.sidebarClose': 'closeSidebar',
    'click @uiCollections.sidebarLinks': 'closeSidebar',
  };

  classNames = {
    activeSidebarMenu: 'store-sidebar__menu_active',
    activeSidebarMenuBackground: 'store-sidebar__menu-background_active',
  };

  onInit() {
    window.addEventListener('resize', this.handleResize);
  }

  openSidebar = () => {
    this.ui.sidebarMenu.classList.add(this.classNames.activeSidebarMenu);
    this.ui.sidebarBackground.classList.add(this.classNames.activeSidebarMenuBackground);
    this.setBodyStyle();
  };

  closeSidebar = () => {
    this.ui.sidebarMenu.classList.remove(this.classNames.activeSidebarMenu);
    this.ui.sidebarBackground.classList.remove(this.classNames.activeSidebarMenuBackground);
    this.setBodyStyle();
  };

  isActiveSidebar = () => {
    return this.ui.sidebarMenu.classList.contains(this.classNames.activeSidebarMenu);
  };

  handleResize = () => {
    if (window.innerWidth >= 1200 && this.isActiveSidebar()) {
      this.closeSidebar();
    }
    this.setBodyStyle();
  };


  setBodyStyle = () => {
    if (window.innerWidth < 1200 && this.isActiveSidebar()) {
      document.body.style.overflow = 'hidden';

      return;
    }
    document.body.style.overflow = 'auto';
  };

}

export default StoreSidebar;
