/* eslint-disable no-underscore-dangle */
export default class UiElement {
  /**
   * Components UI elements
   * After initialization selectors will be replaced with appropriate DOM elements
   *
   * Note: use only for single elements (querySelector)
   */
  ui = {};

  /**
   * Components UI lists of elements
   * After initialization selectors will be replaced with appropriate DOM elements
   *
   * Note: use only for arrays of elements (querySelectorAll)
   */
  uiCollections = {};

  /**
   * Events listeners
   *
   * Format: {'<eventType> <selector>' : '<handlerName>'} or {'<eventType> @ui.<uiElementName>' : '<handlerName>'}
   */
  events = {};

  /**
   * Options
   *
   * rootElement - root element for instance
   */
  options = {};

  static initOnDomLoaded() {
    document.addEventListener('DOMContentLoaded', () => {
      const instance = new this();

      instance.init();
    });
  }

  constructor({ rootElement } = {}) {
    this.options.rootElement = rootElement;
  }

  init(options) {
    this._bindRootElement();
    this._bindUi();
    this._addEventListeners();
    this.onInit(options);

    return this;
  }

  destroy() {
    this._removeEventListeners();

    return this;
  }

  /**
   * Will be invoked on component initialization
   */
  onInit() {}

  _bindRootElement() {
    if (this.options.rootElement) {
      if (this.options.rootElement instanceof HTMLElement) {
        this.rootElement = this.options.rootElement;

        return;
      }

      const rootElement = document.querySelector(this.options.rootElement);

      if (!rootElement) {
        throw new Error(`Cannot find root element by selector '${this.options.rootElement}'`);
      }

      this.rootElement = rootElement;
    } else {
      this.rootElement = document;
    }
  }

  _bindUi() {
    this._uiSelectors = this._uiSelectors || this.ui;

    this.ui = Object.keys(this._uiSelectors).reduce((ui, element) => ({
      ...ui,
      [element]: this.rootElement.querySelector(this._uiSelectors[element]),
    }), {});

    this._uiListSelectors = this._uiListSelectors || this.uiCollections;

    this.uiCollections = Object.keys(this._uiListSelectors).reduce((uiList, element) => ({
      ...uiList,
      [element]: [].slice.call(this.rootElement.querySelectorAll(this._uiListSelectors[element])),
    }), {});
  }

  _parseUiEvent(event) {
    const eventSplitter = /^(\S+)\s+(.+)$/;

    const matchedEvent = event.match(eventSplitter);

    if (!matchedEvent) {
      return [null, []];
    }

    const [eventType, selector] = matchedEvent.slice(1);
    let targets;

    if (selector.startsWith('@ui.')) {
      targets = [this.ui[selector.replace('@ui.', '')]];
    } else if (selector.startsWith('@uiCollections.')) {
      targets = this.uiCollections[selector.replace('@uiCollections.', '')];
    } else if (selector.startsWith('@rootElement')) {
      targets = [this.rootElement];
    } else {
      targets = document.querySelectorAll(selector);
    }

    return [eventType, targets];
  }

  _addEventListeners() {
    Object.keys(this.events).forEach((event) => {
      const [eventType, targets] = this._parseUiEvent(event);
      const eventHandler = this.events[event];
      const boundEventHandler = (typeof eventHandler === 'string') ? this[eventHandler].bind(this) : eventHandler;

      this.events[event] = boundEventHandler;

      [].filter.call(targets, Boolean)
        .forEach((el) => el.addEventListener(eventType, boundEventHandler));
    });
  }

  _removeEventListeners() {
    Object.keys(this.events).forEach((event) => {
      const [eventType, targets] = this._parseUiEvent(event);
      const boundEventHandler = this.events[event];

      [].filter.call(targets, Boolean)
        .forEach((el) => el.removeEventListener(eventType, boundEventHandler));
    });
  }
}
