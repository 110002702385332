import UiElement from '../../../shared/ui-element/ui-element';

class RedirectTo extends UiElement {
  uiCollections = {
    redirectToElement: '[data-redirect-to]',
  };

  events = {
    'click @uiCollections.redirectToElement': 'onRedirectToClick',
  };

  onRedirectToClick(e) {
    const { redirectTo: url } = e.target.dataset;

    window.location.assign(url);
  }
}

export default RedirectTo;
