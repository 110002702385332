import { detect as detectBrowser } from 'detect-browser';

// eslint-disable-next-line import/prefer-default-export
export function detect() {
  let browserInfo = {};
  const browser = detectBrowser();

  if (browser) {
    browserInfo = {
      name: browser.name,
      version: browser.version,
      os: browser.os,
      isMacOS: browser.os.toLowerCase().includes('mac'),
      isiOS: browser.os.toLowerCase().includes('ios'),
    };
  }

  return browserInfo;
}
