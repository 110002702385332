import Logger from '../../utils/logger/logger';

import UiElement from '../../../shared/ui-element/ui-element';
import analytics, { events } from '../../../shared/analytics';

class SignupFormStepName extends UiElement {
  isCaptchaLoaded = false;
  isCaptchaRequired = false;

  ui = {
    termsCheckbox: '.js-terms-accepted',
    submit: '.js-step-name-submit-btn',
    emailPlaceholder: '.js-name-step-email-placeholder',
    formError: '.js-name-step-form-error',
    backButton: '.js-name-step-back-btn',
    retypeButton: '.js-name-step-retype-bth',
    captchaContainer: '.js-captcha-container',
    captcha: '.js-captcha',
  };

  uiCollections = {
    inputsWithError: '.js-input-with-error',
  };

  events = {
    'invalid @ui.termsCheckbox': 'handleTermsCheckboxValidation',
    'submit @rootElement': 'handleFormStepSubmit',
    'click @ui.backButton': 'handleBackButtonClick',
    'click @ui.retypeButton': 'handleRetypeButtonClick',
  };

  constructor(options = {}) {
    super({ rootElement: '#signup-form-step-name' });

    this.onSubmit = options.onSubmit;
    this.onStepChange = options.onStepChange;
    this.onRetypeButtonClick = options.onRetypeButtonClick;
    this.onBackButtonClick = options.onBackButtonClick;
  }

  onInit() {
    this.lazyLoadRecaptcha();
  }

  lazyLoadRecaptcha() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.async = true;
    recaptchaScript.onload = () => {
      this.isCaptchaLoaded = true;
    };
    recaptchaScript.src = 'https://www.google.com/recaptcha/api.js';
    document.body.appendChild(recaptchaScript);
  }

  showFieldError(fieldName, errorText) {
    const field = this.rootElement.elements[fieldName];

    if (!field) {
      return;
    }

    field.parentElement.querySelector('.form-group__invalid-msg').textContent = errorText;
    field.classList.add('form-group__form-control_invalid');
  }

  showFormError(errorText) {
    const errorPlaceholder = this.ui.formError;
    errorPlaceholder.textContent = errorText;
    errorPlaceholder.classList.add('d-block');
  }

  hideErrors() {
    this.uiCollections.inputsWithError.forEach((field) => {
      field.classList.remove('form-group__form-control_invalid');
    });

    this.ui.formError.classList.remove('d-block');
  }

  updateEmailPlaceholder(email) {
    this.ui.emailPlaceholder.textContent = email;
  }

  handleRetypeButtonClick() {
    this.onRetypeButtonClick();
  }

  handleBackButtonClick() {
    this.onBackButtonClick();
  }

  handleShowStepButtonClick(e) {
    this.onStepChange(e.target.dataset.showStep);
  }

  disableSubmitBtn() {
    this.ui.submit.disabled = true;
  }

  enableSubmitBtn() {
    this.ui.submit.disabled = false;
  }

  requireCaptcha() {
    this.isCaptchaRequired = true;
    this.ui.captchaContainer.classList.remove('d-none');
  }

  getCaptchaResponse() {
    if (this.isCaptchaLoaded) {
      return window.grecaptcha.getResponse();
    }

    Logger.catchError('recaptcha script failed to load');

    return null;
  }

  resetCaptcha() {
    if (this.isCaptchaLoaded) {
      window.grecaptcha.reset();
    } else {
      Logger.catchError('recaptcha script failed to load');
    }
  }

  showCaptchaError() {
    this.ui.captcha.classList.add('form-group__form-control_invalid');
  }

  handleTermsCheckboxValidation(e) {
    e.preventDefault();

    if (this.ui.termsCheckbox.validity.valueMissing) {
      this.showFieldError('terms_accepted', this.ui.termsCheckbox.dataset.errorAccept);
    }
  }

  handleFormStepSubmit(e) {
    e.preventDefault();

    this.hideErrors();

    const captchaValue = this.getCaptchaResponse();
    const showCaptchaError = this.isCaptchaRequired && !captchaValue;

    if (showCaptchaError) {
      this.showCaptchaError();
    }

    if (!this.rootElement.checkValidity() || showCaptchaError) {
      return;
    }

    const nameForm = e.currentTarget;
    analytics.trackEvent(events.SIGNUP_FORM_STEP_USERNAME);
    this.onSubmit({
      name: nameForm.elements.name.value,
      marketing_subscribed: nameForm.elements.marketing_subscribed.checked,
      terms_accepted: nameForm.elements.terms_accepted.checked,
      captcha: captchaValue,
    });
  }
}

export default SignupFormStepName;
