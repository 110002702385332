const INSERT_API_ENDPOINT = process.env.APP_ENV === 'production'
  ? 'https://stats.setapp.com/insert'
  : 'https://stats.setapp.macpaw.dev/insert';

function getFeatureFlag(flags, key) {
  return flags.find((ff) => ff.key === key);
}

function getTestGroup(groups, testName) {
  return groups.find((tg) => tg.test_name === testName);
}

function getSatuValue(testData) {
  const identifier = testData.identifier || {};

  return identifier.key === '_satu' ? identifier.value : null;
}

function trackTestGroup(testGroup, dataLayer, satuId) {
  dataLayer.push({
    event: 'abtests',
    slot: testGroup.test_segment_id,
    slot_value: `${testGroup.test_name}:${testGroup.test_group}`,
    satu: satuId,
  });
}

class AnalyticsClient {
  featureFlags = [];

  testGroups = [];

  getTestData() {
    return window.ABN_TESTS_DATA || {};
  }

  getSatuId() {
    return getSatuValue(this.getTestData());
  }

  trackEvent(eventData) {
    window.dataLayer.push({
      ...eventData,
      event: 'setapp',
    });
  }

  trackPageView(title, url) {
    window.dataLayer.push({
      event: 'virtualPageView',
      page: {
        title,
        url,
      },
    });
  }

  trackTestGroup(key) {
    const testData = this.getTestData();

    const featureFlag = getFeatureFlag(testData.feature_flags || [], key);

    if (featureFlag === undefined) {
      return;
    }

    if (!featureFlag.test_name) {
      return;
    }

    const testGroup = getTestGroup(testData.test_groups || [], featureFlag.test_name);

    if (testGroup === undefined) {
      return;
    }

    trackTestGroup(testGroup, window.dataLayer, getSatuValue(testData));
  }

  async insertDatahubData(tableName, data) {
    const payload = {
      _table: tableName,
      _data: data,
    };

    const response = await fetch(INSERT_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error('Insert to Datahub request failed', response.status);
    }
  }
}

export default AnalyticsClient;
