import UiElement from '../../../shared/ui-element/ui-element';

import './store-lang-switcher.scss';

class StoreLangSwitcher extends UiElement {
  events = {
    'click @ui.langSwitcher': 'openLangContainer',
  };

  ui = {
    langSwitcher: '.js-store-lang-switcher-view',
    arrow: '.js-store-lang-switcher-arrow',
    langContainer: '.js-store-lang-switcher-container',
  };

  openClass = {
    arrow: 'store-lang-switcher__arrow_open',
    langContainer: 'store-lang-switcher__lang-container_open',
  };

  constructor(options) {
    super(options);
    this.langContainerMargin = 8;
  }

  openLangContainer() {
    if (this.ui.arrow.classList.contains(this.openClass.arrow)) {
      this.closeLangContainer();

      return;
    }

    this.setLangContainerPosition();
    this.ui.arrow.classList.add(this.openClass.arrow);
    this.ui.langContainer.classList.add(this.openClass.langContainer);
    event.stopPropagation();
    document.addEventListener('click', this.closeLangContainer);
  }

  closeLangContainer = () => {
    this.ui.arrow.classList.remove(this.openClass.arrow);
    this.ui.langContainer.classList.remove(this.openClass.langContainer);
    document.removeEventListener('click', this.closeLangContainer);
  };

  setLangContainerPosition() {
    const langViewHeight = this.ui.langSwitcher.clientHeight;
    const yMargin = langViewHeight / 2 + this.langContainerMargin;

    const { top, left } = this.ui.langSwitcher.getBoundingClientRect();

    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

    this.ui.langContainer.style = '';

    this.ui.langContainer.style[(top < viewportHeight / 2) ? 'top' : 'bottom'] = `${yMargin}px`;

    this.ui.langContainer.style[(left < viewportWidth / 2) ? 'left' : 'right'] = '0';
  }
}

export default StoreLangSwitcher;
