import UiElement from '../../../shared/ui-element/ui-element';
import LangSwitcher from '../lang-switcher/lang-switcher';

import '../../layout/_footer.scss';
import '../../layout/_store-footer.scss';

class Footer extends UiElement {
  ui = {
    langSwitcher: '.site-footer__lang-switcher',
  };

  onInit() {
    if (this.ui.langSwitcher) {
      new LangSwitcher({ rootElement: this.ui.langSwitcher }).init();
    }
  }
}

export default Footer;
