import UiElement from '../../../shared/ui-element/ui-element';

import './lang-switcher.scss';

class LangSwitcher extends UiElement {
  events = {
    'change @rootElement': 'handleLangSwitcherChange',
  };

  handleLangSwitcherChange({ target }) {
    const href = this.rootElement.querySelector(`option[value=${target.value}]`).dataset.lang;
    window.location.assign(href);
  }
}

export default LangSwitcher;
