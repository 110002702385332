import UiElement from '../../../shared/ui-element/ui-element';
import IntersectionObserver from '../../utils/intersection-observer/intersection-observer';
import AnimationListeners from '../../utils/animation-listeners/animation-listeners';
import LangSwitcher from '../lang-switcher/lang-switcher';
import ReferralButton from '../referral-button/referral-button';

class Navigation extends UiElement {
  ui = {
    bar: '.site-navigation__bar',
    overlay: '.site-navigation__overlay',
    menuSlider: '.site-navigation__menu-slider',
    logoCaption: '.site-navigation .logo__caption',
    openButton: '.site-navigation__open-button',
    signupButton: '.site-navigation__signup-button',
    langSwitcher: '.site-navigation__lang-switcher',
  };

  uiCollections = {
    closeElements: '[data-navigation-close]',
    ctaButtons: '.js-site-navigation-cta',
    promoCtaButtons: '.js-site-navigation-promo-cta',
  };

  events = {
    'click @ui.openButton': 'handleOpenClick',
    'click @uiCollections.closeElements': 'handleCloseClick',
  };

  static stickyNavigationTarget = '[data-sticky-navigation-target]';

  static staticNavigationBarClass = 'site-navigation__bar_static';

  static stickyNavigationBarClass = 'site-navigation__bar_sticky';

  static primaryActionButtonClass = 'btn_outline-primary';

  static whiteNavigationBarClass = 'site-navigation__bar_white';

  static whiteLogoCaptionClass = 'logo__caption_white';

  static overlayShownClass = 'site-navigation__overlay_shown';

  static overlayHiddenClass = 'site-navigation__overlay_hidden';

  static menuSliderOpenedClass = 'site-navigation__menu-slider_open';

  static menuSliderClosedClass = 'site-navigation__menu-slider_closed';

  static langSwitcherWhiteClass = 'lang-switcher_white';

  constructor(options) {
    super({ rootElement: '.js-site-navigation', ...options });
  }

  onInit() {
    const { bar } = this.ui;

    if (this.ui.langSwitcher) {
      new LangSwitcher({ rootElement: this.ui.langSwitcher }).init();
    }

    new ReferralButton().init();

    this.isNavigationWhite = this.rootElement.dataset.whiteTheme === '1';
    this.defaultCtaThemeClass = this.rootElement.dataset.defaultCtaThemeClass;

    this.setBarSlideListeners();

    const stickyNavigationTarget = document.querySelector(Navigation.stickyNavigationTarget);

    new IntersectionObserver(this.handleScrollToTarget)
      .observe(stickyNavigationTarget || bar);
  }

  setBarSlideListeners() {
    const { bar } = this.ui;
    const animationListeners = new AnimationListeners();

    animationListeners.add(bar, 'start', this.onSlideStart);
    animationListeners.add(bar, 'end', this.onSlideEnd);
  }

  onSlideStart = ({ animationName }) => {
    const { bar } = this.ui;

    if (animationName === 'navigationSlideDown') {
      bar.style.position = 'fixed';
      bar.style.top = 0;
    }
  };

  onSlideEnd = ({ animationName }) => {
    const { bar } = this.ui;

    if (animationName === 'navigationSlideUp') {
      bar.style.position = 'absolute';
      bar.style.top = 'auto';
    }
  };

  handleOpenClick = () => {
    const { menuSlider, overlay } = this.ui;

    menuSlider.classList.remove(Navigation.menuSliderClosedClass);
    overlay.classList.remove(Navigation.overlayHiddenClass);

    menuSlider.classList.add(Navigation.menuSliderOpenedClass);
    overlay.classList.add(Navigation.overlayShownClass);
  };

  handleCloseClick = () => {
    const { menuSlider, overlay } = this.ui;

    menuSlider.classList.add(Navigation.menuSliderClosedClass);
    overlay.classList.add(Navigation.overlayHiddenClass);

    menuSlider.classList.remove(Navigation.menuSliderOpenedClass);
    overlay.classList.remove(Navigation.overlayShownClass);
  };

  handleScrollToTarget = ({ up, down }) => {
    if (up) {
      this.makeStatic();

      if (this.ui.langSwitcher && this.isNavigationWhite) {
        this.enableLangSwitcherWhiteTheme();
      }
    }

    if (down) {
      this.makeSticky();

      if (this.ui.langSwitcher) {
        this.disableLangSwitcherWhiteTheme();
      }
    }
  };

  enableLangSwitcherWhiteTheme() {
    this.ui.langSwitcher.classList.add(Navigation.langSwitcherWhiteClass);
  }

  disableLangSwitcherWhiteTheme() {
    this.ui.langSwitcher.classList.remove(Navigation.langSwitcherWhiteClass);
  }

  makeStatic() {
    const {
      bar,
      logoCaption,
    } = this.ui;
    const { ctaButtons, promoCtaButtons } = this.uiCollections;

    bar.classList.add(Navigation.staticNavigationBarClass);
    bar.classList.remove(Navigation.stickyNavigationBarClass);

    if (this.isNavigationWhite) {
      bar.classList.add(Navigation.whiteNavigationBarClass);
      logoCaption.classList.add(Navigation.whiteLogoCaptionClass);
    }

    ctaButtons.forEach((button) => {
      if (this.defaultCtaThemeClass !== Navigation.primaryActionButtonClass) {
        button.classList.remove(Navigation.primaryActionButtonClass);
        button.classList.add(this.defaultCtaThemeClass);
      }
    });

    promoCtaButtons.forEach((button) => {
      button.classList.remove('d-flex');
      button.classList.add('d-none');
    });
  }

  makeSticky() {
    const {
      bar,
      logoCaption,
    } = this.ui;
    const { ctaButtons, promoCtaButtons } = this.uiCollections;

    bar.classList.add(Navigation.stickyNavigationBarClass);
    bar.classList.remove(Navigation.staticNavigationBarClass);

    if (this.isNavigationWhite) {
      bar.classList.remove(Navigation.whiteNavigationBarClass);
      logoCaption.classList.remove(Navigation.whiteLogoCaptionClass);
    }

    ctaButtons.forEach((button) => {
      if (this.defaultCtaThemeClass !== Navigation.primaryActionButtonClass) {
        button.classList.remove(this.defaultCtaThemeClass);
        button.classList.add(Navigation.primaryActionButtonClass);
      }
    });

    promoCtaButtons.forEach((button) => {
      button.classList.remove('d-none');
      button.classList.add('d-flex');
    });
  }
}

export default Navigation;
