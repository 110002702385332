import UiElement from '../../../shared/ui-element/ui-element';
import analytics, { events } from '../../../shared/analytics';

class SignupFormStepPassword extends UiElement {
  ui = {
    inputPassword: '.js-password-input',
    togglePasswordButton: '.js-toggle-password',
    emailPlaceholder: '.js-password-step-email-placeholder',
    emailHiddenInput: '.js-password-step-email-hidden-input',
    passwordErrorPlaceholder: '.js-password-error-msg',
    backButton: '.js-password-step-back-btn',
    retypeButton: '.js-password-step-retype-bth',
    passwordRequirementsTooltip: '.js-password-requirements-tooltip',
    passwordRuleLength: '.js-password-requirement-length',
    passwordRuleUpper: '.js-password-requirement-upper',
    passwordRuleLower: '.js-password-requirement-lower',
  };

  events = {
    'input @ui.inputPassword': 'handlePasswordInputLive',
    'invalid @ui.inputPassword': 'handlePasswordInputValidation',
    'submit @rootElement': 'handleFormStepSubmit',
    'click @ui.togglePasswordButton': 'togglePasswordDisplaying',
    'click @ui.backButton': 'handleBackButtonClick',
    'click @ui.retypeButton': 'handleRetypeButtonClick',
  };

  regexPatterns = {
    length: /.{8,}/,
    upper: /.*?\p{Lu}/u,
    lower: /.*?\p{Ll}/u,
    lowerAndUpper: /(?=.*?\p{Lu})|(?=.*?\p{Ll})/u,
  };

  tooltipVisibleClass = 'signup-form__password-requirements_visible';

  tooltipItemInvalidClass = 'signup-form__password-requirements__item_invalid';

  tooltipItemValidClass = 'signup-form__password-requirements__item_valid';

  constructor(options = {}) {
    super({ rootElement: '#signup-form-step-password' });

    this.onSubmit = options.onSubmit;
    this.onRetypeButtonClick = options.onRetypeButtonClick;
    this.onBackButtonClick = options.onBackButtonClick;
  }

  showPasswordFieldError = (errorText) => {
    this.ui.passwordErrorPlaceholder.textContent = errorText;
    this.ui.inputPassword.classList.add('form-group__form-control_invalid');
    this.ui.passwordRequirementsTooltip.classList.add(this.tooltipVisibleClass);
  };

  hidePasswordFieldError = () => {
    this.ui.inputPassword.classList.remove('form-group__form-control_invalid');
  };

  updateEmailPlaceholder(email) {
    this.ui.emailPlaceholder.textContent = email;
    this.ui.emailHiddenInput.value = email;
  }

  togglePasswordDisplaying(e) {
    const button = e.target;
    this.ui.inputPassword.focus();

    if (this.ui.inputPassword.type === 'password') {
      this.ui.inputPassword.type = 'text';
      button.setAttribute('aria-label', button.dataset.hideTextAriaLabel);
      button.textContent = button.dataset.hideText;
    } else {
      this.ui.inputPassword.type = 'password';
      button.setAttribute('aria-label', button.dataset.showTextAriaLabel);
      button.textContent = button.dataset.showText;
    }
  }

  handleRetypeButtonClick() {
    this.onRetypeButtonClick();
  }

  handleBackButtonClick() {
    this.onBackButtonClick();
  }

  handlePasswordInputLive(e) {
    e.preventDefault();

    this.hidePasswordFieldError();
    this.ui.passwordRequirementsTooltip.classList.remove(this.tooltipVisibleClass);

    const passwordValue = this.ui.inputPassword.value;

    const passwordRules = [
      [this.ui.passwordRuleLength, this.regexPatterns.length],
      [this.ui.passwordRuleUpper, this.regexPatterns.upper],
      [this.ui.passwordRuleLower, this.regexPatterns.lower],
    ];

    passwordRules.forEach(([element, rule]) => {
      element.classList.remove(this.tooltipItemInvalidClass);
      element.classList.toggle(this.tooltipItemValidClass, rule.test(passwordValue));
    });
  }

  handlePasswordInputValidation(e) {
    e.preventDefault();
    const passwordInput = this.ui.inputPassword;
    const { dataset } = passwordInput;

    if (passwordInput.validity.valueMissing) {
      this.showPasswordFieldError(`${dataset.errorLowerAndUpper} ${dataset.errorLength}`);
      this.ui.passwordRuleLength.classList.add(this.tooltipItemInvalidClass);
      this.ui.passwordRuleUpper.classList.add(this.tooltipItemInvalidClass);
      this.ui.passwordRuleLower.classList.add(this.tooltipItemInvalidClass);
    }

    if (passwordInput.validity.patternMismatch) {
      let completeErrorMessage = '';
      const characterRules = [
        {
          pattern: this.regexPatterns.lowerAndUpper,
          message: dataset.errorLowerAndUpper,
          elems: [this.ui.passwordRuleLower, this.ui.passwordRuleUpper],
        },
        {
          pattern: this.regexPatterns.lower,
          message: dataset.errorLower,
          elems: [this.ui.passwordRuleLower],
        },
        {
          pattern: this.regexPatterns.upper,
          message: dataset.errorUpper,
          elems: [this.ui.passwordRuleUpper],
        },
      ];

      if (passwordInput.value.length < 8) {
        completeErrorMessage = dataset.errorLength;
        this.ui.passwordRuleLength.classList.add(this.tooltipItemInvalidClass);
      }

      for (let i = 0; i < characterRules.length; i++) {
        const validationPass = characterRules[i].pattern.test(passwordInput.value);
        if (!validationPass) {
          completeErrorMessage = `${characterRules[i].message} ${completeErrorMessage}`;
          characterRules[i].elems.forEach((elem) => elem.classList.add(this.tooltipItemInvalidClass));
          break;
        }
      }

      if (completeErrorMessage) {
        this.showPasswordFieldError(completeErrorMessage);
      }
    }
  }

  handleFormStepSubmit = (e) => {
    e.preventDefault();
    if (!this.rootElement.checkValidity()) {
      return;
    }

    const password = e.currentTarget.elements.password.value;
    analytics.trackEvent(events.SIGNUP_FORM_STEP_PASSWORD);
    this.onSubmit({ password });
    this.hidePasswordFieldError();
  };
}

export default SignupFormStepPassword;
