import MicroModal from 'micromodal';

import UiElement from '../../../shared/ui-element/ui-element';
import analytics from '../../../shared/analytics';

import SignupFormManager from '../signup-form/signup-form-manager';

import './signup-modal.scss';

export default class SignupModal extends UiElement {
  ui = {
    modal: '#signup-modal',
    modalCloseBtn: '#signup-modal .modal__close',
    modalOverlay: '#signup-modal .modal__overlay',
  };

  constructor() {
    super();

    this.signupFormManager = new SignupFormManager();
  }

  open(options = {}) {
    const { hideCloseBtn } = options;

    if (hideCloseBtn) {
      this.hideCloseBtn();
    }

    MicroModal.show('signup-modal', {
      onClose: this.onClose,
      closeTrigger: 'data-base-modal-close',
      disableScroll: true,
    });

    this.onOpen(options);
  }

  onOpen = ({
    tierType, signupHideSocials, campaign, eventLabel,
  } = {}) => {
    analytics.trackPageView('Embedded Signup Flow', '/embedded-signup');

    this.signupFormManager.init({
      signupParams: { tier_type: tierType },
      signupHideSocials,
      campaign,
      eventLabel,
    });
  };

  onClose = () => {
    this.signupFormManager.destroy();
  };

  hideCloseBtn() {
    this.ui.modalCloseBtn.classList.add('modal__close_hidden');
  }
}
