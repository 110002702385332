import cookies from 'js-cookie';
import UiElement from '../../../shared/ui-element/ui-element';
import config from '../../../shared/config/config';

import './cookie-banner.scss';

class CookieBanner extends UiElement {
  ui = {
    acceptButton: '.js-accept-cookies',
    banner: '.js-cookie-banner',
  };

  events = {
    'click @ui.acceptButton': 'handleAcceptButton',
  };

  static acceptedCookieName = 'cookieAccepted';

  handleAcceptButton() {
    this.saveAcceptingFlag();
    this.destroy();
    this.ui.banner.remove();
  }

  saveAcceptingFlag() {
    cookies.set(CookieBanner.acceptedCookieName, '1', { domain: config.get('cookiesDomain'), expires: 365 * 5 });
  }
}

export default CookieBanner;
