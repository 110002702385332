import axios from 'axios';
import UiElement from '../../../shared/ui-element/ui-element';
import analytics, { events } from '../../../shared/analytics';
import Logger from '../../utils/logger/logger';

class EmailSubscription extends UiElement {
  isCaptchaRequired = false;

  ui = {
    emailInput: '.js-email-subscription-email-input',
    emailBtn: '.js-email-subscription-submit-btn',
    captchaContainer: '#js-footer-captcha-container',
    captcha: '.js-captcha',
    captchaError: '.js-captcha-error',
    commonError: '.js-common-error',
  };

  events = {
    'submit @rootElement': 'handleFormSubmit',
  };

  static formGroupControlValidClass = 'form-group__form-control_valid';

  static formGroupControlInvalidClass = 'form-group__form-control_invalid';

  constructor(options, captchaProvider) {
    super(options);

    this.onSubscribeSuccess = options.onSubscribeSuccess || (() => {});
    this.captchaProvider = captchaProvider;
  }

  onInit() {
    this.apiUrl = this.rootElement.action;
  }

  getCaptchaResponse() {
    if (this.captchaProvider.isCaptchaReady) {
      return window.grecaptcha.getResponse(this.captchaProvider.captchaFooterWidgetId);
    } else {
      Logger.catchError('recaptcha script failed to load');

      return null;
    }
  }

  resetCaptcha() {
    if (this.captchaProvider.isCaptchaReady) {
      window.grecaptcha.reset(this.captchaProvider.captchaFooterWidgetId);
    } else {
      Logger.catchError('recaptcha script failed to load');
    }
  }

  requireCaptcha() {
    this.isCaptchaRequired = true;
    this.ui.captchaContainer.classList.remove('d-none');
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    const btn = this.ui.emailBtn;

    this.requireCaptcha();
    btn.disabled = true;

    const captchaValue = this.getCaptchaResponse();
    const showCaptchaError = this.isCaptchaRequired && !captchaValue;

    if (showCaptchaError) {
      btn.disabled = false;
      this.showCaptchaError();

      return;
    }

    const emailInput = this.ui.emailInput;
    const email = emailInput.value;

    try {
      await axios.post(this.apiUrl, { email });

      this.hideCaptcha();
      this.resetCaptcha();

      this.emailSubscriptionSuccess();
      this.onSubscribeSuccess();
    } catch {
      this.emailSubscriptionError();
      this.resetCaptcha();
    } finally {
      btn.disabled = false;
    }
  }

  setValidFormControl(formControl) {
    formControl.classList.remove(EmailSubscription.formGroupControlInvalidClass);
    formControl.classList.add(EmailSubscription.formGroupControlValidClass);
  }

  setInvalidFormControl(formControl) {
    formControl.classList.remove(EmailSubscription.formGroupControlValidClass);
    formControl.classList.add(EmailSubscription.formGroupControlInvalidClass);
  }

  emailSubscriptionSuccess = () => {
    this.setValidFormControl(this.ui.emailInput);

    this.sendAnalyticsEvent();
  };

  emailSubscriptionError = () => {
    this.setInvalidFormControl(this.ui.emailInput);

    this.ui.captchaError.classList.add('d-none');
    this.ui.commonError.classList.remove('d-none');
  };

  showCaptchaError() {
    this.setInvalidFormControl(this.ui.emailInput);

    this.ui.captchaError.classList.remove('d-none');
    this.ui.commonError.classList.add('d-none');
  }

  hideCaptcha() {
    this.isCaptchaRequired = false;
    this.ui.captchaContainer.classList.add('d-none');
  }

  sendAnalyticsEvent = () => {
    analytics.trackEvent({
      ...events.EMAIL_SUBSCRIPTION,
      eventLabel: 'Footer',
    });
  };
}

export default EmailSubscription;
