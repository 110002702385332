import BaseModal from '../base-modal/base-modal';

import './video-modal.scss';

export default class VideoModal extends BaseModal {
  openTrigger = 'data-video-modal-open';

  closeTrigger = 'data-video-modal-close';

  getIframe(url) {
    const iframe = document.createElement('iframe');

    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', '100%');
    iframe.setAttribute('src', url);
    iframe.setAttribute('frameborder', '0');
    iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', '');

    return iframe;
  }

  onOpen = (modal) => {
    const modalContent = modal.querySelector('.js-modal-video');

    this.iframe = this.getIframe(modalContent.dataset.video);

    modalContent.appendChild(this.iframe);
  };

  onClose = (modal) => {
    modal.querySelector('.js-modal-video').removeChild(this.iframe);
  };
}
