import queryString from 'query-string';

import healthMetrics from '../../utils/health-metrics/health-metrics';
import Logger from '../../utils/logger/logger';

import analytics from '../../../shared/analytics';
import ClickTracker from '../../../shared/click-tracker/click-tracker';
import UiElement from '../../../shared/ui-element/ui-element';

import EmailSubscription from '../email-subscription/email-subscription';
import StoreEmailSubscription from '../store-email-subscription-base/store-email-subscription-base';
import StoreLangSwitcher from '../store-lang-switcher/store-lang-switcher';
import Navigation from '../navigation/navigation';
import StoreNavigation from '../store-navigation/store-navigation';
import StoreSidebar from '../store-sidebar/store-sidebar';
import StoreSearch from '../store-search/store-search';
import Footer from '../footer/footer';
import SignupModal from '../signup-modal/signup-modal';
import CookieBanner from '../cookie-banner/cookie-banner';
import EmergencyMessage from '../emergency-message/emergency-message';
import RedirectTo from '../redirect-to/redirect-to';
import CaptchaSubscriptionBannerProvider from '../captcha-provider/captcha-subscription-banner-provider';
import BlogSubscribeBanner from '../blog-subscribe-banner/blog-subscribe-banner';

import '../../main.scss';

export const captchaProvider = new CaptchaSubscriptionBannerProvider();

class BasePage extends UiElement {

  signupModalOptions = {
    hideCloseBtn: false,
  };

  constructor() {
    super();

    this.ui = {
      subscribeBanner: 'js-subscribe-banner',
    };

    this.signupModal = new SignupModal();
    this.analytics = analytics;
  }

  onInit() {
    Logger.initialize();
    captchaProvider.init();

    const webStoreEnabled = window.ABN_TESTS_DATA?.feature_flags.some((flag) => flag?.key === 'webStoreEnabled' && flag?.value);

    if (webStoreEnabled) {
      new StoreLangSwitcher({ rootElement: '.js-store-lang-switcher' }).init();

      new StoreNavigation({ rootElement: '.js-store-navigation' }).init();

      new StoreSidebar({ rootElement: '.js-store-sidebar' }).init();

      new StoreSearch({ rootElement: '.js-store-search' }).init();

      new StoreSearch({ rootElement: '.js-store-sidebar-search' }).init();

      const storeSubscriptionForm = document.querySelector('.js-store-subscription-form');

      if (storeSubscriptionForm) {
        new StoreEmailSubscription({ rootElement: storeSubscriptionForm }).init();
      }
    } else {
      new EmailSubscription({ rootElement: '.js-base-subscription-form' }, captchaProvider).init();

      new Navigation().init();
    }

    if (this.ui.subscribeBanner) {
      new BlogSubscribeBanner({ rootElement: '.js-subscribe-banner' }, captchaProvider).init();
    }


    new ClickTracker().init();
    new CookieBanner().init();
    new EmergencyMessage().init();
    new RedirectTo().init();
    new Footer().init();
    healthMetrics.initialize();

    this.signupModal.init();
    this.setSignupModalOptions();
    this.initSignUpButtons();
    this.openSignupModalFromExternalSourceIfNeeded();
    this.trackPageView();
  }

  setSignupModalOptions() {
    const query = queryString.parse(window.location.search);

    if ('hideModalCloseBtn' in query) {
      this.signupModalOptions = { ...this.signupModalOptions, hideCloseBtn: true };
    }
  }

  trackPageView() {
    healthMetrics.trackIncrement({ name: 'page_view' });
  }

  initSignUpButtons() {
    const buttons = document.querySelectorAll('[data-signup-button]');

    [].forEach.call(buttons, (button) => {
      button.addEventListener('click', this.handleSignUpButtonClick);
    });
  }

  handleSignUpButtonClick = (e) => {
    const { tierType, signupHideSocials, eventLabel } = e.currentTarget.dataset;

    this.signupModal.open({
      ...this.signupModalOptions, tierType, signupHideSocials, eventLabel,
    });
  };

  // This is a function to open Signup modal for
  // those users who came from external landings with additional get params
  openSignupModalFromExternalSourceIfNeeded() {
    const query = queryString.parse(window.location.search);
    const externalParamsInUrl = ['appId', 'affiliates'];

    externalParamsInUrl.some((param) => {
      if (param in query) {
        this.signupModal.open(this.signupModalOptions);

        return true;
      }

      return false;
    });
  }
}

export default BasePage;
