class IntersectionObserver {
  handler;

  targetPosition;

  canFireHandlerUp = false;

  get pageTop() {
    return document.documentElement.scrollTop || document.body.scrollTop;
  }

  get targetReachedDown() {
    return this.pageTop >= this.targetPosition;
  }

  get targetReachedUp() {
    return !this.targetReachedDown && this.canFireHandlerUp;
  }

  /**
   * Create observer.
   * @arg {function} handler - callback which will be fired every time when we pass target.
   */
  constructor(handler) {
    this.handler = handler;
  }

  /**
   * Start observe.
   * @arg {Element} target - anchor element for observer.
   * @arg [{string}] side - which side of element to observe: bottom or top.
   */
  observe(target, side = 'bottom') {
    if (!(target instanceof HTMLElement)) return;

    this.setTargetPosition(target, side);

    // if target reached on page load
    if (this.targetReachedDown) {
      this.canFireHandlerUp = true;
      this.handler({ up: false, down: true });
    }

    document.addEventListener('scroll', this.onPageScroll);
  }

  setTargetPosition = (target, side) => {
    this.targetPosition = target.getBoundingClientRect()[side] + this.pageTop;
  };

  onPageScroll = () => {
    // Fixes unnecessary callback fire during first time scroll
    this.canFireHandlerUp = this.canFireHandlerUp || this.targetReachedDown;

    if (this.targetReachedDown) {
      this.handler({ up: false, down: true });
    }

    if (this.targetReachedUp) {
      this.handler({ up: true, down: false });
    }
  };
}

export default IntersectionObserver;
