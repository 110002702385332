import HealthMetricsHelper from '@setapp/health-metrics-helper';

import config from '../../../shared/config/config';
import Logger from '../logger/logger';

export const healthMetricsApiRoot = process.env.HEALTH_METRICS_API_ROOT;

export class HealthMetrics {
  healthMetrics;

  isInitialized = false;

  initialize() {
    this.healthMetrics = new HealthMetricsHelper({
      apiRoot: config.get('healthMetricsAPI.root'),
      appName: 'setapp_site',
      onError: this.onTrackingError,
    });
    this.isInitialized = true;
  }

  onTrackingError(message) {
    Logger.catchError(message);
  }

  trackMetric(metricMethod, payload) {
    if (!this.isInitialized) {
      return;
    }

    if (typeof this.healthMetrics[metricMethod] !== 'function') {
      this.onTrackingError(`Method ${metricMethod} is not supported by health metrics helper.`);

      return;
    }

    this.healthMetrics[metricMethod](payload);
  }

  trackIncrement(payload) {
    this.trackMetric('increment', payload);
  }

  trackDecrement(payload) {
    this.trackMetric('decrement', payload);
  }

  trackTiming(payload) {
    this.trackMetric('timing', payload);
  }
}

const healthMetrics = new HealthMetrics();

export default healthMetrics;
