import axios from 'axios';
import UiElement from '../../../shared/ui-element/ui-element';

import analytics, { events } from '../../../shared/analytics';

import './store-email-subscription-base.scss';

class StoreEmailSubscription extends UiElement {
  ui = {
    emailInput: '.js-email-subscription-email-input',
    emailBtn: '.js-email-subscription-submit-btn',
    emailCheckbox: '.js-email-subscription-confirmation',
    emailError: '.js-email-subscription-error',
    successMessage: '.js-email-subscription-success',
    customEmailError: '.js-email-subscription-custom-error',
    confirmationError: '.js-email-subscription-confirmation-error',
    policyContainer: '.js-policy-container',
  };

  events = {
    'submit @rootElement': 'handleFormSubmit',
  };

  displayClass = {
    inputError: 'form-group__form-control_invalid',
    show: 'store-email-subscription-base__show',
    hide: 'store-email-subscription-base__hide',
    checkboxInvalid: 'store-email-subscription-base__checkbox-invalid',
    success: 'form-group__form-control_valid',
  };

  constructor(options) {
    super(options);

    this.onSubscribeSuccess = options.onSubscribeSuccess;
  }

  onInit() {
    this.apiUrl = this.rootElement.action;

    this.ui.emailCheckbox.addEventListener('change', (e) => {
      this.checkAgreeWithPrivacyPolicy(e.target.checked, Boolean(this.ui.emailInput.value));
    });
    this.ui.emailInput.addEventListener('input', () => {
      this.removeEmailError();
      this.removeEmailSuccess();
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const btn = this.ui.emailBtn;

    const email = this.ui.emailInput.value.trim();
    const readAndAgreeWithPrivacyPolicy = this.ui.emailCheckbox.checked;

    const isEmailValid = this.checkIsEmailValid(email);
    const isAgreeWithPrivacyPolicy = this.checkAgreeWithPrivacyPolicy(readAndAgreeWithPrivacyPolicy, Boolean(email));

    if (!btn.disabled && isEmailValid && isAgreeWithPrivacyPolicy) {
      btn.disabled = true;

      return axios.post(this.apiUrl, { email, readAndAgreeWithPrivacyPolicy })
        .then(this.emailSubscriptionSuccess)
        .catch(this.emailSubscriptionError);

    } else {
      return false;
    }
  }

  checkIsEmailValid = (email) => {
    const isEmailValid = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

    if (!isEmailValid) {
      this.ui.emailError.classList.add(this.displayClass.show);
      this.ui.emailInput.classList.add(this.displayClass.inputError);
    } else {
      this.removeEmailError();
    }

    return isEmailValid;
  };

  removeEmailError = () => {
    this.ui.emailBtn.disabled = false;
    this.ui.emailError.classList.remove(this.displayClass.show);
    this.ui.customEmailError.classList.remove(this.displayClass.show);
    this.ui.emailInput.classList.remove(this.displayClass.inputError);
  };

  removeEmailSuccess = () => {
    this.ui.emailBtn.disabled = false;
    this.ui.successMessage.classList.remove(this.displayClass.show);
    this.ui.emailInput.classList.remove(this.displayClass.success);
    this.ui.policyContainer.classList.remove(this.displayClass.hide);
  };

  checkAgreeWithPrivacyPolicy = (checked, isEmailNotEmpty) => {
    if (!checked && isEmailNotEmpty) {
      this.ui.confirmationError.classList.add(this.displayClass.show);
      this.ui.emailCheckbox.classList.add(this.displayClass.checkboxInvalid);
    } else {
      this.ui.confirmationError.classList.remove(this.displayClass.show);
      this.ui.emailCheckbox.classList.remove(this.displayClass.checkboxInvalid);
    }

    return checked;
  };

  emailSubscriptionSuccess = () => {
    this.ui.emailInput.classList.add(this.displayClass.success);
    this.ui.policyContainer.classList.add(this.displayClass.hide);
    this.ui.successMessage.classList.add(this.displayClass.show);
    this.sendAnalyticsEvent();
    if (this.onSubscribeSuccess) this.onSubscribeSuccess();
  };

  emailSubscriptionError = (e) => {
    this.ui.emailBtn.disabled = false;
    const { response } = e;

    const customError = response?.data?.errors?.[0]?.detail;

    if (customError) {
      this.serverError = this.ui.customEmailError.textContent;
      this.ui.customEmailError.textContent = customError;
    } else if (this.serverError) { this.ui.customEmailError.textContent = this.serverError; }

    this.ui.customEmailError.classList.add(this.displayClass.show);
    this.ui.emailInput.classList.add(this.displayClass.inputError);
  };

  sendAnalyticsEvent = () => {
    analytics.trackEvent({
      ...events.EMAIL_SUBSCRIPTION,
      eventLabel: 'Footer',
    });
  };
}

export default StoreEmailSubscription;
