import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import packageJSON from '../../../../../package.json';
import config from '../../../shared/config/config';

const beforeSend = (event) => {
  try {
    if (event.exception
      && event.exception.values
      && event.exception.values[0]?.value === 'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target') {
      return null;
    }
  } catch (err) {
    return event;
  }

  return event;
};

export const loggerConfig = {
  dsn: config.get('sentryDsn'),
  allowUrls: [/https?:\/\/((cdn|www)\.)?setapp\.com/], // for local development comment this out
  environment: config.get('environment'),
  release: `setapp-site@${packageJSON.version}`,
  integrations: [
    new ExtraErrorData({ depth: 5 }),
    new Sentry.BrowserTracing(),
  ],
  tracesSampleRate: 0.03,
  beforeSend,
  ignoreErrors: [
    // Chrome extensions
    'Non-Error promise rejection captured with value: null',
    'Extension context invalidated.',
    'a[b].target.className.indexOf is not a function. (In \'a[b].target.className.indexOf(Zb)\', \'a[b].target.className.indexOf\' is undefined)',
    // Tempermonkey - user scripts
    'window.GM_getTab is not a function',
    'undefined is not an object (evaluating \'window.crypto.subtle.generateKey\')',
    // Ad/tracker blocking
    '\'UET\' is undefined',
    'UET is not defined',
    'Can\'t find variable: UET',
    // Safari-extensions
    'SyntaxError: The string did not match the expected pattern.',
    '{"line":247003,"sourceURL":"user-script:1","column":22}',
    // GTM
    'ReferenceError: fbq is not defined',
    'TypeError: ‘undefined’ is not an object (evaluating ‘a.Shopify.checkout’)',
    // ResizeObserver
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
    // iOS and Mac OS webview error
    'undefined is not an object (evaluating \'window.webkit.messageHandlers.selectedDebugHandler.postMessage\')',
    // Other extensions
    'Can\'t find variable: pktAnnotationHighlighter',
    // Same error on the same page spamming from one constant IP
    'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
  ],
};

class Logger {
  static initialize() {
    if (config.get('environment') !== 'local') {
      Sentry.init(loggerConfig);
    }
  }

  static catchError(message, details?) {
    if (config.get('environment') === 'local') {
      // eslint-disable-next-line no-console
      console.error(message, details);
    }

    Sentry.withScope((scope) => {
      if (details) {
        scope.setExtras({ details });
      }
      Sentry.captureMessage(message, 'error');
    });
  }
}

export default Logger;
