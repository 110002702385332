import { animate, spring } from 'motion';

import BasePage from '../../components/base-page/base-page';
import Carousel from '../../components/carousel/carousel';
import VideoModal from '../../components/video-modal/video-modal';

import breakpoints from '../../config/breakpoints';

import './home.scss';

class HomePage extends BasePage {
  ui = {
    headerMacpawBadge: '.js-header-macpaw-badge',
    testimonialsCarousel: '.js-testimonials-carousel',
    videoTestimonialsCarousel: '.js-video-testimonials-carousel',
  };

  uiCollections = {
    headerBadges: '.js-header-badge',
  };

  events = {
    'mouseenter @uiCollections.headerBadges': 'handleBadgeMouseEnter',
    'mouseenter @ui.headerMacpawBadge': 'handleMacpawBadgeMouseEnter',
    'mouseleave @uiCollections.headerBadges': 'handleBadgeMouseLeave',
  };

  badgeRotationAnimation = null;

  onInit() {
    super.onInit();

    this.testimonialsCarousel = new Carousel({
      rootElement: this.ui.testimonialsCarousel,
      slidesPerPage: {
        [breakpoints.sm]: 2,
        [breakpoints.lg]: 3,
      },
    });
    this.testimonialsCarousel.init();

    this.videoTestimonialsCarousel = new Carousel({
      rootElement: this.ui.videoTestimonialsCarousel,
    });
    this.videoTestimonialsCarousel.init();

    this.videoModal = new VideoModal();
    this.videoModal.init();
  }

  handleBadgeMouseEnter = ({ target }) => {
    animate(target,
      { rotate: target.dataset.rotateByHoverTo, scale: 1.1 },
      { easing: spring({ stiffness: 500, damping: 15 }) }
    );
  };

  handleBadgeMouseLeave = ({ target }) => {
    animate(target,
      { rotate: 0, scale: 1 },
      { easing: spring({ stiffness: 500, damping: 15 }) }
    );
  };

  handleMacpawBadgeMouseEnter = ({ target }) => {
    if (this.badgeRotationAnimation && this.badgeRotationAnimation.playState === 'running') {
      return;
    }

    this.badgeRotationAnimation = animate(target,
      { transform: ['rotate(0deg)', 'rotate(360deg)'] },
      { duration: 0.8, easing: 'ease' }
    );
  };
}

export default HomePage;

HomePage.initOnDomLoaded();
