const animationEventsSets = {
  animationDuration: {
    start: 'animationstart',
    end: 'animationend',
    iteration: 'animationiteration',
  },
  WebkitAnimationDuration: {
    start: 'webkitAnimationStart',
    end: 'webkitAnimationEnd',
    iteration: 'webkitAnimationIteration',
  },
  OAnimationDuration: {
    start: 'oAnimationStart',
    end: 'oAnimationEnd',
    iteration: 'oAnimationIteration',
  },
  msAnimationDuration: {
    start: 'MSAnimationStart',
    end: 'MSAnimationEnd',
    iteration: 'MSAnimationIteration',
  },
  MozAnimationDuration: {
    start: 'animationstart',
    end: 'animationend',
    iteration: 'animationiteration',
  },
};

export default class AnimationListeners {
  eventsSet;

  constructor() {
    this.defineSuitableEventsSet();
  }

  defineSuitableEventsSet() {
    Object.keys(animationEventsSets).forEach((setName) => {
      if (typeof document.body.style[setName] !== 'undefined') {
        this.eventsSet = animationEventsSets[setName];
      }
    });

    if (!this.eventsSet) {
      this.eventsSet = animationEventsSets.animationDuration;
    }
  }

  /**
  * Add a listener for elements animation event
  * @param {HTMLElement} element The element
  * @param {string} eventName `start`, `end` or `iteration`
  * @param {function} callback The callback
  */
  add(element, eventName, callback) {
    const event = this.eventsSet[eventName];

    if (!event || typeof callback !== 'function' || !(element instanceof HTMLElement)) return;

    element.addEventListener(event, callback);
  }
}
