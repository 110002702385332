import UiElement from '../../../shared/ui-element/ui-element';

import './store-search.scss';

class StoreSearch extends UiElement {
  ui = {
    searchInput: '.js-store-search-input',
    searchClearButton: '.store-search__close-btn',
  };

  events = {
    'focus @ui.searchInput': 'handleFocusInput',
    'blur @ui.searchInput': 'handleBlurInput',
    'keydown @ui.searchInput': 'handleEnterPress',
    'input @ui.searchInput': 'handleInputValueChange',
    'click @ui.searchClearButton': 'handleClearSearch',
  };

  classNames = {
    activeSearch: 'store-search_active',
    hideClearButton: 'd-none',
  };

  onInit() {
    this.toggleClearButtonVisibility();
  }

  handleFocusInput = () => {
    this.rootElement.classList.add(this.classNames.activeSearch);
  };

  handleBlurInput = () => {
    this.rootElement.classList.remove(this.classNames.activeSearch);
  };

  handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      const query = this.ui.searchInput.value;
      if (!query) return;

      window.location.href = `/search?q=${encodeURIComponent(query)}`;
    }
  };

  handleInputValueChange = () => {
    this.toggleClearButtonVisibility();
  };

  toggleClearButtonVisibility = () => {
    const value = this.ui.searchInput.value;
    if (value) {
      this.ui.searchClearButton.classList.remove(this.classNames.hideClearButton);

      return;
    }

    this.ui.searchClearButton.classList.add(this.classNames.hideClearButton);
  };

  handleClearSearch = () => {
    this.ui.searchInput.value = '';
    this.toggleClearButtonVisibility();
  };
}

export default StoreSearch;
