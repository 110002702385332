import cookies from 'js-cookie';
import UiElement from '../../../shared/ui-element/ui-element';
import config from '../../../shared/config/config';
import analytics, { events } from '../../../shared/analytics';

import './emergency-message.scss';

class EmergencyMessage extends UiElement {
  ui = {
    closeButton: '.js-close-emergency-message',
    banner: '.js-emergency-message',
  };

  events = {
    'click @ui.closeButton': 'handleCloseButton',
  };

  static closedCookieName = 'emergencyMessageClosed';

  handleCloseButton() {
    analytics.trackEvent(events.WAR_BANNER_CLOSE);
    this.saveClosedFlag();
    this.destroy();
    this.ui.banner.remove();
  }

  saveClosedFlag() {
    cookies.set(EmergencyMessage.closedCookieName, '1', { domain: config.get('cookiesDomain'), expires: 31 });
  }
}

export default EmergencyMessage;
