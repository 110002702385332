import analytics from '../analytics/index';

export default class ClickTracker {
  init() {
    document.body.addEventListener('click', this.onBodyClick, false);
  }

  onBodyClick(e) {
    const { dataset } = e.target;
    const eventData = {};

    if (!(dataset && dataset.eventAction && dataset.eventCategory)) {
      return false;
    }

    eventData.eventAction = dataset.eventAction;
    eventData.eventCategory = dataset.eventCategory;

    if (dataset.eventLabel) {
      eventData.eventLabel = dataset.eventLabel;
    }

    if (dataset.eventValue) {
      const eventValue = Number(dataset.eventValue);

      if (Number.isNaN(eventValue)) {
        throw new Error('Wrong type of eventValue parameter provided, expected integer');
      }

      eventData.eventValue = eventValue;
    }

    if (dataset.eventNonInteraction) {
      eventData.eventNonInteraction = dataset.eventNonInteraction === 'true';
    }

    analytics.trackEvent(eventData);

    return true;
  }
}
