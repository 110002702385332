type Subscriber = (value: any) => void;

class Subject {
  private _handlers: Subscriber[] = [];

  get observers() {
    return this._handlers;
  }

  subscribe = (fn: Subscriber): void => {
    this._handlers.push(fn);
  };

  unsubscribe = (fn: Subscriber): void => {
    this._handlers = this._handlers.filter((item) => item !== fn);
  };

  fire = (context: Subject, value: any): void => {
    const scope = context || this;
    this._handlers.forEach((item: Subscriber) => {
      item.call(scope, value);
    });
  };
}

export default Subject;

