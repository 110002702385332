import MicroModal from 'micromodal';
import UiElement from '../../../shared/ui-element/ui-element';

export default class BaseModal extends UiElement {
  modal = null;

  openTrigger = 'data-base-modal-open';

  closeTrigger = 'data-base-modal-close';

  onOpen = (f) => f;

  onClose = (f) => f;

  close() {
    if (!this.modal) {
      return;
    }

    const clickEvent = new Event('click', {
      bubbles: true,
      cancelable: true,
    });
    const closeButton = this.modal.querySelector(`[${this.closeTrigger}]`);

    closeButton.dispatchEvent(clickEvent);
  }

  onOpenHandler = (modal) => {
    this.modal = modal;
    this.onOpen(modal);
  };

  onCloseHandler = (modal) => {
    this.onClose(modal);
    this.modal = null;
  };

  onInit() {
    MicroModal.init({
      openTrigger: this.openTrigger,
      closeTrigger: this.closeTrigger,
      onShow: this.onOpenHandler,
      onClose: this.onCloseHandler,
      disableScroll: true,
    });
  }
}
