import { animate } from 'motion';
import { camelizeKeys } from 'humps';
import cookies from 'js-cookie';
import { isAvailable } from '@setapp/desktop-app-helper';
import Logger from '../../utils/logger/logger';
import request from '../../utils/request/request';

import { UserTypes } from '../../utils/state/user/user-types';
import type { Referral } from '../../utils/state/referrals/referrals-types';
import type { User } from '../../utils/state/user/user-initial-state-types';

import config from '../../../shared/config/config';

import UiElement from '../../../shared/ui-element/ui-element';

import './referral-button.scss';

const SUCCESS_ICON = '/static/main/images/success-icon.svg';
const GIFT_ICON = '/static/main/images/gift-icon-white.svg';

class ReferralButton extends UiElement {
  events = {
    'click @uiCollections.referralButtons': 'handleClickReferralButton',
    'mouseenter @uiCollections.referralBalloons': 'handleMouseInsideBalloon',
    'mouseleave @uiCollections.referralBalloons': 'handleMouseOutsideBalloon',
  };

  static referralBtnWrapper = '.js-referral-btn-wrapper';

  static activeBtnClass = 'btn_with-icon_active';

  static staticBtnClass = 'btn_with-icon_static';

  private referralLinkCookieName!: string;
  private referralLink;
  private balloonTimeOut;
  private isCopied = false;

  constructor() {
    super();

    this.uiCollections = {
      downloadButtons: '.js-download-btn',
      referralBalloonContainers: '.js-balloon-container',
      referralBalloons: '.js-referral-balloon',
      referralBtnWrappers: '.js-referral-btn-wrapper',
      referralButtons: '.js-referral-button',
      referralButtonsText: '.js-referral-button-text',
      referralButtonsIcon: '.js-referral-button-icon',
    };
  }

  onInit() {
    const [referralBtnWrapper] = this.uiCollections.referralBtnWrappers ?? [];

    this.referralLinkCookieName = referralBtnWrapper?.dataset?.referralLinkCookieName ?? '';
    this.referralLink = referralBtnWrapper?.dataset?.referralLinkValue ?? '';

    isAvailable({
      onSuccess: this.renderReferralButtonBasedOnConditions,
      onError: this.handleReferralUnavailable,
    });
  }

  async canUseReferralProgram() {
    const userProfile = await this.fetchUserProfile();

    if (!userProfile) {
      return false;
    }

    const { type, familyMember } = userProfile;
    const isFreeUser = type === UserTypes.Free;
    const isPlainFamilyMember = Boolean(familyMember && !familyMember.isOwner);

    return !isFreeUser && !isPlainFamilyMember;
  }

  renderReferralButtonBasedOnConditions = async () => {
    const canUseReferralProgram = await this.canUseReferralProgram();

    if (!canUseReferralProgram) {
      this.handleReferralUnavailable();

      return;
    }

    await this.fetchReferralLink();

    if (this.referralLink) {
      cookies.set(this.referralLinkCookieName, this.referralLink, { domain: config.get('cookiesDomain'), expires: 365 });

      document.addEventListener('click', (event) => this.handleClickOutsideBalloon(event));

      this.handleHideDownloadBtn();
      this.handleShowReferralBtn();
    }
  };

  async fetchReferralLink() {
    try {
      const response: Referral = await request.get(config.get('customerAccountAPI.referral'));
      this.referralLink = response.url;
    } catch (error) {
      this.handleReferralUnavailable();

      Logger.catchError('Can not fetch referral link', error);
    }
  }

  async fetchUserProfile(): Promise<User | null> {
    try {
      const response: User = await request.get(config.get('customerAccountAPI.account'));

      return camelizeKeys(response);
    } catch (error) {
      return null;
    }
  }

  async handleClickReferralButton() {
    const referralButtonsText = this.uiCollections.referralButtonsText ?? [];
    const referralButtonsIcon = this.uiCollections.referralButtonsIcon as HTMLImageElement[] ?? [];
    const referralBtnWrappers = this.uiCollections.referralBtnWrappers ?? [];
    const referralBtnLinkCopiedText = referralBtnWrappers[0]!.dataset.referralBtnLinkCopiedText!;

    await this.handleCopyReferralLinkToClipBoard();

    this.setReferralButtonStaticClasses();

    if (this.isCopied) {
      this.addBalloonTimeout();

      return;
    }

    this.animateShowBalloon();
    this.animateReferralButtonContent(
      referralButtonsText,
      () => (
        referralButtonsText.forEach((referralButtonText) => {
          referralButtonText.textContent = referralBtnLinkCopiedText;
        })
      ),
    );
    this.animateReferralButtonContent(
      referralButtonsIcon,
      () => (
        referralButtonsIcon.forEach((referralButtonIcon) => (referralButtonIcon.src = SUCCESS_ICON))
      ),
    );

    this.isCopied = true;
    this.addBalloonTimeout();
  }

  async handleCopyReferralLinkToClipBoard() {
    if (this.referralLink) {
      await navigator.clipboard.writeText(this.referralLink);
    }
  }

  handleClickOutsideBalloon(event: Event) {
    const targetElement = event.target as Element;
    const closestElement = targetElement.closest(ReferralButton.referralBtnWrapper);

    if (closestElement !== null || !this.isCopied) {
      return;
    }

    this.clearBalloonTimeout();
    this.setReferralButtonDefaultAnimationState();
    this.setReferralButtonActiveClasses();

    if (this.isCopied) {
      this.animateHideBalloon();
    }
  }

  handleMouseInsideBalloon() {
    this.clearBalloonTimeout();
  }

  handleMouseOutsideBalloon = () => {
    this.addBalloonTimeout();
  };

  handleShowReferralBtn() {
    (this.uiCollections.referralButtons as unknown as HTMLButtonElement[]).forEach((referralButton) => {
      referralButton.classList.remove('d-none');
    });
  }

  handleHideReferralBtn() {
    (this.uiCollections.referralButtons as unknown as HTMLButtonElement[]).forEach((referralButton) => {
      referralButton.classList.add('d-none');
    });
  }

  handleShowDownloadBtn() {
    (this.uiCollections.downloadButtons as unknown as HTMLButtonElement[]).forEach((downloadButton) => {
      downloadButton.classList.remove('d-none');
    });
  }

  handleHideDownloadBtn() {
    (this.uiCollections.downloadButtons as unknown as HTMLButtonElement[]).forEach((downloadButton) => {
      downloadButton.classList.add('d-none');
    });
  }

  handleReferralUnavailable = () => {
    this.handleHideReferralBtn();
    this.handleShowDownloadBtn();

    this.referralLink = '';

    const cookieValue = cookies.get(this.referralLinkCookieName);

    if (cookieValue) cookies.remove(this.referralLinkCookieName, { domain: config.get('cookiesDomain') });
  };

  setReferralButtonStaticClasses() {
    const referralButtons = this.uiCollections.referralButtons as unknown as HTMLElement[];

    referralButtons.forEach((referralButton) => {
      referralButton.classList.remove(ReferralButton.activeBtnClass);
      referralButton.classList.add(ReferralButton.staticBtnClass);
    });
  }

  setReferralButtonActiveClasses() {
    const referralButtons = this.uiCollections.referralButtons as unknown as HTMLElement[];

    referralButtons.forEach((referralButton) => {
      referralButton.classList.remove(ReferralButton.staticBtnClass);
      referralButton.classList.add(ReferralButton.activeBtnClass);
    });
  }

  setReferralButtonDefaultAnimationState = () => {
    const referralButtonsText = this.uiCollections.referralButtonsText as unknown as HTMLElement[];
    const referralButtonsIcon = this.uiCollections.referralButtonsIcon as unknown as HTMLImageElement[];
    const referralBtnWrappers = this.uiCollections.referralBtnWrappers as unknown as HTMLElement[];
    const referralBtnTitleText = referralBtnWrappers[0]!.dataset.referralBtnTitleText!;

    this.animateReferralButtonContent(
      referralButtonsText,
      () => (
        referralButtonsText.forEach((referralButtonText) => {
          referralButtonText.textContent = referralBtnTitleText;
        })
      ),
    );
    this.animateReferralButtonContent(
      referralButtonsIcon,
      () => (
        referralButtonsIcon.forEach((referralButtonIcon) => {
          referralButtonIcon.src = GIFT_ICON;
        })
      ),
    );
  };

  clearBalloonTimeout() {
    if (this.balloonTimeOut) {
      clearTimeout(this.balloonTimeOut);
      this.balloonTimeOut = undefined;
    }
  }

  addBalloonTimeout() {
    const referralButtons = this.uiCollections.referralButtons as unknown as HTMLButtonElement[];

    if (this.balloonTimeOut) {
      clearTimeout(this.balloonTimeOut);
    }

    this.balloonTimeOut = setTimeout(() => {
      this.setReferralButtonDefaultAnimationState();
      this.setReferralButtonActiveClasses();
      this.animateHideBalloon();

      referralButtons.forEach((referralButton) => referralButton.blur());
    }, 5000);
  }

  animateReferralButtonContent(elements: HTMLElement[], updater: () => void) {
    animate(elements,
      {
        opacity: [1, 0],
      },
      {
        duration: 0.25,
        easing: 'ease-in-out',
      }
    ).finished.then(() => {
      animate(elements,
        {
          opacity: [0, 1],
        },
        {
          duration: 0.25,
          easing: 'ease-in-out',
        }
      );
      updater();
    });
  }

  animateShowBalloon() {
    (this.uiCollections.referralBalloonContainers as unknown as HTMLElement[]).forEach((referralBalloonContainer) => {
      referralBalloonContainer.style.display = 'block';
    });

    animate((this.uiCollections.referralBalloons as unknown as HTMLElement[]),
      {
        opacity: [0, 1],
        transform: ['translateY(20px)', 'translateY(0)'],
      },
      {
        duration: 0.3,
        easing: 'ease-in-out',
      },
    );
  }

  animateHideBalloon() {
    animate((this.uiCollections.referralBalloons as unknown as HTMLElement[]),
      {
        opacity: [1, 0],
      },
      {
        duration: 0.3,
        easing: 'ease-in-out',
      },
    ).finished.then(() => {
      (this.uiCollections.referralBalloonContainers as unknown as HTMLElement[]).forEach((referralBalloonContainer) => {
        referralBalloonContainer.style.display = 'none';
      });
      this.isCopied = false;
    });
  }
}

export default ReferralButton;
