/* eslint-disable no-underscore-dangle, func-names, object-shorthand */

import axios from 'axios';
import httpStatus from 'http-status';
import TokenManager from '@setapp/auth-tokens-manager';

import config from '../../../shared/config/config';

const request = {
  get: function (url) {
    return this._sendRequest(url, { method: 'GET' });
  },

  post: function (url, meta) {
    return this._sendRequest(url, { ...meta, method: 'POST' });
  },

  _tokenManager: new TokenManager({
    cookiesDomain: config.get('cookiesDomain'),
    secureCookies: window.location.protocol === 'https:',
    rememberPeriod: 365,
    accessTokenName: 'customer_access_token',
    refreshTokenName: 'customer_refresh_token',
  }),

  _sendRequest: function (url, options = {}) {
    const request = this._buildRequest(url, options);

    return request()
      .then((response) => this._handleExpiredAuthToken(request, response))
      .then((response) => {
        if (typeof response.data === 'string') return response.data;

        return response.data.data;
      })
      .catch((error) => Promise.reject(error));
  },

  _handleExpiredAuthToken: function (request, response) {
    if (response.status !== httpStatus.UNAUTHORIZED) {
      return Promise.resolve(response);
    }

    return this._requestNewAccessToken()
      .then(request)
      .catch(() => Promise.reject(new Error('Refresh token is empty')));
  },

  _requestNewAccessToken: function () {
    const refreshToken = this._tokenManager.getRefreshToken(),
      requestOptions = {
        withoutTokenAuth: true,
        body: { refresh_token: refreshToken },
      };

    if (!refreshToken) {
      return Promise.reject();
    }

    return this.post(config.get('customerAccountAPI.accessToken'), requestOptions)
      .then(({ token }) => this._tokenManager.saveAccessToken(token))
      .catch((error) => Promise.reject(error));
  },

  _validateResponseStatus: function (status) {
    return (status >= httpStatus.OK && status < httpStatus.MULTIPLE_CHOICES) || status === httpStatus.UNAUTHORIZED;
  },

  _buildRequest: function (url, meta) {
    const options = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
      validateStatus: this._validateResponseStatus,
    };

    return () => {
      if (!meta.withoutTokenAuth) {
        options.headers.Authorization = `Token ${this._tokenManager.getAccessToken()}`;
      }

      const { body, ...restMeta } = meta;

      if (body) {
        options.data = JSON.stringify(body);
      }

      return axios(url, { ...options, ...restMeta });
    };
  },
};

export default request;
