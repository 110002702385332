import Siema from 'siema';
import UiElement from '../../../shared/ui-element/ui-element';

import './carousel.scss';

class Carousel extends UiElement {
  constructor({
    slidesPerPage = 1,
    randomOnInit = false,
    baseClass = 'carousel',
    loop = true,
    duration = 300,
    interval,
    linksSelector,
    onSlideChangeCallback,
    ...parentOptions
  } = {}) {
    super(parentOptions);

    this.slidesPerPage = slidesPerPage;
    this.randomOnInit = randomOnInit;
    this.baseClass = baseClass;
    this.loop = loop;
    this.duration = duration;
    this.interval = interval;
    this.onSlideChangeCallback = onSlideChangeCallback;

    this.uiCollections.links = linksSelector;
  }

  uiCollections = {
    paginators: '[data-carousel="pagination"]',
    slides: '.js-carousel-slide',
  };

  ui = {
    slides: '[data-carousel="slides"]',
    nextBtn: '[data-carousel="next"]',
    prevBtn: '[data-carousel="prev"]',
  };

  events = {
    'click @ui.nextBtn': 'handleNextBtnClick',
    'click @ui.prevBtn': 'handlePrevBtnClick',
    'click @uiCollections.paginators': 'handlePaginationClick',
    'mousedown @uiCollections.slides': 'handleMouseDownOnSlide',
    'mousemove @uiCollections.slides': 'handleMouseMoveOnSlide',
    'mouseup @uiCollections.slides': 'handleMouseUpOnSlide',
    'click @uiCollections.links': 'handleClickOnLink',
  };

  onInit() {
    const startIndex = this.getStartIndex();

    this.carousel = new Siema({
      selector: this.ui.slides,
      duration: this.duration,
      easing: 'ease-out',
      draggable: true,
      loop: this.loop,
      perPage: this.slidesPerPage,
      onChange: this.handleSlideChange,
      startIndex,
    });

    this.updatePagination(startIndex);

    if (this.interval) {
      this.showNextSlideInInterval();
    }
  }

  getStartIndex() {
    if (!this.randomOnInit) {
      return 0;
    }

    const numberOfSlides = this.ui.slides.childElementCount;

    return Carousel.getIntegerUpTo(numberOfSlides);
  }

  destroy() {
    this.carousel.destroy(true);
  }

  handleSlideChange = () => {
    if (this.onSlideChangeCallback) {
      this.onSlideChangeCallback();
    }

    this.updatePagination(this.carousel.currentSlide);

    if (this.interval) {
      this.showNextSlideInInterval();
    }
  };

  handleNextBtnClick() {
    this.carousel.next();
  }

  handlePrevBtnClick() {
    this.carousel.prev();
  }

  handlePaginationClick(e) {
    this.carousel.goTo(Number(e.currentTarget.dataset.carouselPaginationId));
  }

  updatePagination(activeItemId) {
    this.uiCollections.paginators.forEach((item, idx, items) => {
      /*
      * Hack for handling Siema bug. If there's several slides displayed on the screen and user drags to the second lap
      * Siema returns -1, -2, -3, etc as slide index
      * */
      const itemId = activeItemId < 0 ? items.length + activeItemId : activeItemId;
      const activeClass = `${this.baseClass}__pagination-item_active`;

      item.classList.toggle(activeClass, idx === itemId);
    });
  }

  showNextSlideInInterval() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.handleNextBtnClick.bind(this), this.interval);
  }

  handleMouseDownOnSlide() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.isSwiping = false;
  }

  handleMouseMoveOnSlide() {
    this.isSwiping = true;
  }

  handleMouseUpOnSlide() {
    if (this.interval) {
      this.showNextSlideInInterval();
    }
  }

  handleClickOnLink(e) {
    if (this.isSwiping) {
      e.preventDefault();
    }
  }

  handleResize() {
    this.carousel.resizeHandler();
  }

  static getIntegerUpTo(max) {
    return Math.floor(Math.random() * (max));
  }
}

export default Carousel;
