import UiElement from '../../../shared/ui-element/ui-element';
import IntersectionObserver from '../../utils/intersection-observer/intersection-observer';

class StoreNavigation extends UiElement {
  ui = {
    separator: '.store-navigation__menu-separator',
    logoText: '.store-logo__text',
    search: '.store-search',
    button: '.store-navigation__account-button',
    burgerButton: '.store-navigation__burger-container',
    burgerMenu: '.store-navigation__burger-menu',
    burgerBackground: '.store-navigation__burger-menu-background',
  };

  events = {
    'click @ui.burgerButton': 'toggleMenu',
  };

  classNames = {
    sticky: 'store-navigation_sticky',
    static: 'store-navigation_static',
    darkModeMain: 'store-navigation_dark',
    darkSeparator: 'store-navigation__menu-separator_dark',
    darkLogo: 'store-logo__text_dark-mode',
    darkSearch: 'store-search_dark',
    darkButton: 'store-navigation__account-button_dark',
    activeMenu: 'store-navigation__burger-menu_active',
    activeBurgerBackground: 'store-navigation__burger-menu-background_active',
  };

  onInit() {
    this.isDarkMode = this.rootElement.classList.contains('store-navigation_dark');

    new IntersectionObserver(this.handleScrollToTarget)
      .observe(this.rootElement);
  }


  handleScrollToTarget = ({ up, down }) => {
    if (up) {
      this.makeStatic();
    }

    if (down) {
      this.makeSticky();
    }
  };

  makeStatic() {
    this.rootElement.classList.add(this.classNames.static);
    this.rootElement.classList.remove(this.classNames.sticky);
    this.addDarkMode();
  }

  makeSticky() {
    this.rootElement.classList.add(this.classNames.sticky);
    this.rootElement.classList.remove(this.classNames.static);
    this.removeDarkMode();
  }

  addDarkMode = () => this.changeTheme(true);
  removeDarkMode = () => this.changeTheme();

  changeTheme(isAddMode) {
    const mode = isAddMode ? 'add' : 'remove';
    if (this.isDarkMode) {
      this.rootElement.classList[mode](this.classNames.darkModeMain);
      this.ui.separator.classList[mode](this.classNames.darkSeparator);
      this.ui.button?.classList[mode](this.classNames.darkButton);
      this.ui.logoText.classList[mode](this.classNames.darkLogo);
      this.ui.search.classList[mode](this.classNames.darkSearch);
    }
  }

  toggleMenu = () => {
    if (this.ui.burgerMenu.classList.contains(this.classNames.activeMenu)) {
      document.removeEventListener('click', this.toggleMenu);
      document.removeEventListener('scroll', this.toggleMenu);
      this.ui.burgerMenu.classList.remove(this.classNames.activeMenu);
      this.ui.burgerBackground.classList.remove(this.classNames.activeBurgerBackground);
    } else {
      document.addEventListener('click', this.toggleMenu);
      document.addEventListener('scroll', this.toggleMenu);
      this.ui.burgerMenu.classList.add(this.classNames.activeMenu);
      this.ui.burgerBackground.classList.add(this.classNames.activeBurgerBackground);
    }
  };

}

export default StoreNavigation;
